import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';

import Layout from '../../components/layout/layout';
import Seo from '../../components/layout/seo';
import { PaymentRedirect } from '../../components/checkout/payment-and-summary/payment-redirect';
import client from '@/webapi/client';

import { WithCheckout } from '../../hooks/useCheckoutState';
import lookupCheckoutId from '../../hooks/checkout-id';

import { track } from '../../components/tracking/segment';

const PaymentRedirectPage = () => {
  const checkoutId = lookupCheckoutId();

  useEffect(() => {
    track('Checkout Step Viewed', {
      checkout_id: checkoutId,
      step_name: 'payment-details',
      substep_name: 'payment-redirect',
    });
  }, [checkoutId]);

  return (
    <ApolloProvider client={client}>
      <WithCheckout>
        <Layout withCheckoutFooter={false}>
          <Seo title="Dance" />
          <PaymentRedirect />
        </Layout>
      </WithCheckout>
    </ApolloProvider>
  );
};

export default PaymentRedirectPage;
