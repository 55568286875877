import React, { useEffect } from 'react';
import { navigate } from 'gatsby-plugin-react-intl';
import lookupCheckoutId from '../../../hooks/checkout-id';
import { track } from '../../tracking/segment';
import LoadingSpinner from '../../layout/loadingspinner';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useGoal } from 'gatsby-plugin-fathom';
import { useCheckoutState } from '../../../hooks/useCheckoutState';
import stripePromise from '../../../helpers/loadStripe';

export const PaymentRedirect = () => {
  const {
    config: { stripeKey },
  } = useCheckoutState();

  return (
    <Elements stripe={stripePromise(stripeKey)}>
      <PaymentRedirectChecker />
    </Elements>
  );
};

const PaymentRedirectChecker = () => {
  const checkoutId = lookupCheckoutId();
  const stripe = useStripe();
  const checkoutGoal = useGoal('PVLQGPMI');

  useEffect(() => {
    async function checkStripeResponse(stripe: Stripe, checkoutId: string) {
      const urlParams = new URLSearchParams(window.location.search);
      const clientSecret = urlParams.get('payment_intent_client_secret');

      const response = await stripe.retrievePaymentIntent(`${clientSecret}`);
      const stripeError =
        response.error || response.paymentIntent?.last_payment_error;

      if (!stripeError) {
        checkoutGoal();
        navigate('/m/start');
        return;
      }

      track('Checkout Step Error', {
        checkout_id: checkoutId,
        step_name: 'payment-details',
        substep_name: 'payment-redirect',
        error: 'StripeCheckout redirectError',
        error_message: stripeError.code,
        payment_method: stripeError.payment_method,
      });

      const redirectUrlParams = new URLSearchParams();
      redirectUrlParams.append(
        'stripe_error',
        'payment-method-redirect-failed'
      );
      if (stripeError.payment_method?.type) {
        redirectUrlParams.append(
          'payment_method',
          stripeError.payment_method.type
        );
      }

      navigate(`/c/payment?${redirectUrlParams.toString()}`);
    }

    if (checkoutId && stripe) {
      checkStripeResponse(stripe, checkoutId);
    }
  }, [checkoutId, stripe]);

  return <LoadingSpinner />;
};
